<template>
  <div id="displayCustomisation">
    <div
      :class="{ active: loading }"
      class="ui inverted dimmer"
    >
      <div class="ui loader" />
    </div>
    
    <h1 v-if="project && feature_type">
      Modifier l'affichage sur la carte des signalements de type "{{ feature_type.title }}" pour le
      projet "{{ project.title }}"
    </h1>
    
    <div id="symbology">
      <h3>Symbologie</h3>
      <form
        id="form-symbology-edit"
        action=""
        method="post"
        enctype="multipart/form-data"
        class="ui form"
      >
        <SymbologySelector
          v-if="feature_type"
          id="default"
          :init-color="feature_type.color"
          :init-icon="feature_type.icon"
          :init-opacity="feature_type.opacity"
          :geom-type="feature_type.geom_type"
          @set="setDefaultStyle"
        />
        <div
          v-if="customizableFields.length > 0"
          class="fields inline"
        >
          <label
            id="customfield-select-label"
            for="customfield-select"
          >
            Champ de personnalisation de la symbologie:
          </label>
          <div id="custom_types-dropdown">
            <Dropdown
              :options="customizableFields"
              :selected="selectedCustomfield"
              :selection.sync="selectedCustomfield"
              :clearable="true"
            />
          </div>
        </div>
        <div
          v-if="selectedCustomfield"
          id="customFieldSymbology"
          class="field"
        >
          <SymbologySelector
            v-for="option of selectedFieldOptions"
            :id="option"
            :key="option"
            :title="option"
            :init-color="feature_type.colors_style.value ?
              feature_type.colors_style.value.colors[option] ?
                feature_type.colors_style.value.colors[option].value :
                feature_type.colors_style.value.colors[option]
              : null
            "
            :init-icon="feature_type.colors_style.value ?
              feature_type.colors_style.value.icons[option] :
              null
            "
            :init-opacity="getOpacity(feature_type, option)"
            :geom-type="feature_type.geom_type"
            @set="setColorsStyle"
          />
        </div>
      </form>
    </div>

    <div class="ui divider" />

    <div
      v-if="feature_type && feature_type.customfield_set"
      id="popupDisplay"
    >
      <h3>Prévisualisation des champs personnalisés de l'info-bulle</h3>
      <table
        id="table-fields-to-display"
        class="ui definition single line compact table"
        aria-describedby="Liste des champs à afficher"
      >
        <thead>
          <tr>
            <th scope="col">
              Prévisualisation du champ
            </th>
            <th scope="col">
              Champ
            </th>
            <th scope="col">
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="field in featureAnyFields"
            :key="field.name"
            :class="{ first_customfield: feature_type.customfield_set[0] &&
              field.name === feature_type.customfield_set[0].name }"
          >
            <td
              scope="row" 
              class="collapsing center aligned"
            >
              <div class="ui toggle checkbox">
                <input
                  :checked="form.displayed_fields.includes(field.name)"
                  type="checkbox"
                  @input="toggleDisplay($event, field.name)"
                >
                <label />
              </div>
            </td>
            <td scope="row">
              {{ field.name }} ({{ field.label }})
            </td>
            <td scope="row">
              {{ field.field_type || getCustomFieldType(field.field_type) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <button
      id="save-display"
      class="ui teal icon button margin-25"
      type="button"
      :disabled="!canSaveDisplayConfig"
      @click="sendDisplayConfig"
    >
      <i
        class="white save icon"
        aria-hidden="true"
      />
      Sauvegarder l'affichage du type de signalement
    </button>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import { customFieldTypeChoices, featureNativeFields } from '@/utils';
import SymbologySelector from '@/components/FeatureType/SymbologySelector.vue';
import Dropdown from '@/components/Dropdown.vue';


export default {
  name: 'FeatureTypeDisplay',

  components: {
    SymbologySelector,
    Dropdown,
  },

  data() {
    return {
      loading: false,
      form: {
        color: '#000000',
        icon: 'circle',
        colors_style: {
          fields: [],
          colors: {},
          icons: {},
          opacities: {},
          custom_field_name: '',
          value: {
            colors: {},
            icons: {},
            opacities: {},
          }
        },
        displayed_fields: ['status', 'feature_type', 'updated_on']
      },
      canSaveDisplayConfig: false
    };
  },

  computed: {
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature-type', [
      'customForms',
      'colorsStyleList'
    ]),
    ...mapGetters('feature-type', [
      'feature_type'
    ]),
    customizableFields() {
      if (this.feature_type) {
        let options = this.feature_type.customfield_set.filter(el => el.field_type === 'list' || el.field_type === 'char' || el.field_type === 'boolean');
        options = options.map((el) => {
          return { name: [el.name, this.getCustomFieldType(el.field_type)], value: el };
        });
        return options;
      }
      return [];
    },
    selectedFieldOptions() {
      if (this.selectedCustomfield) {
        const customFieldSet = this.feature_type.customfield_set.find(el => el.name === this.selectedCustomfield);
        if (customFieldSet) {
          if (customFieldSet.options && customFieldSet.options.length > 0) {
            return customFieldSet.options;
          } else if (customFieldSet.field_type === 'char') {
            return ['Vide', 'Non vide'];
          } else if (customFieldSet.field_type === 'boolean') {
            return ['Décoché', 'Coché'];
          }
        }
      }
      return [];
    },
    selectedCustomfield: {
      get() {
        return this.form.colors_style.custom_field_name;
      },
      set(newValue) {
        if (newValue !== undefined) {
          this.form.colors_style.custom_field_name = newValue.value ? newValue.value.name : null;
        }
      }
    },
    featureAnyFields() {
      return [...featureNativeFields, ...this.feature_type.customfield_set];
    }
  },

  watch: {
    feature_type(newValue) {
      if (newValue) {
        // Init form
        this.form.color = JSON.parse(JSON.stringify(newValue.color));
        this.form.icon = JSON.parse(JSON.stringify(newValue.icon));
        this.form.colors_style = {
          ...this.form.colors_style,
          ...JSON.parse(JSON.stringify(newValue.colors_style))
        };
      }
    },
    form: {
      deep: true,
      handler(newValue) {
        if (isEqual(newValue, {
          color: this.feature_type.color, 
          icon: this.feature_type.icon,
          opacity: this.feature_type.opacity,
          colors_style: this.feature_type.colors_style,
          displayed_fields: this.feature_type.displayed_fields
        })) {
          this.canSaveDisplayConfig = false;
        } else {
          this.canSaveDisplayConfig = true;
        }
      }
    }
  },

  created() {
    if (!this.project) {
      this.GET_PROJECT(this.$route.params.slug);
      this.GET_PROJECT_INFO(this.$route.params.slug);
    }
    this.SET_CURRENT_FEATURE_TYPE_SLUG(this.$route.params.slug_type_signal);
    if (this.feature_type) {
      this.initForm();
    } else {
      this.loading = true;
      this.GET_PROJECT_FEATURE_TYPES(this.$route.params.slug)
        .then(() => {
          this.initForm();
          // TODO : Use the global loader and get rid of this redondant loader
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    ...mapMutations('feature-type', [
      'SET_CURRENT_FEATURE_TYPE_SLUG'
    ]),
    ...mapActions('feature-type', [
      'SEND_FEATURE_DISPLAY_CONFIG',
      'GET_PROJECT_FEATURE_TYPES'
    ]),
    ...mapActions('projects', [
      'GET_PROJECT',
      'GET_PROJECT_INFO',
    ]),
  
    initForm() {
      this.form.color = JSON.parse(JSON.stringify(this.feature_type.color)); //? wouldn't be better to use lodash: https://medium.com/@pmzubar/why-json-parse-json-stringify-is-a-bad-practice-to-clone-an-object-in-javascript-b28ac5e36521
      this.form.icon = JSON.parse(JSON.stringify(this.feature_type.icon)); //? since the library is already imported ?
      this.form.colors_style = {
        ...this.form.colors_style,
        ...JSON.parse(JSON.stringify(this.feature_type.colors_style))
      };
      if (!this.form.colors_style.value['opacities']) { //* if the opacity values were never setted (would be better to find out why)
        this.form.colors_style.value['opacities'] = {};
      }
      if (this.feature_type.colors_style && Object.keys(this.feature_type.colors_style.colors).length > 0) {
        const coloredCustomField = this.feature_type.customfield_set.find(
          el => el.name === this.feature_type.colors_style.custom_field_name
        );
        if (coloredCustomField) { 
          this.selectedCustomfield = coloredCustomField.name;
        }
      }
      if (this.feature_type && this.feature_type.displayed_fields) {
        this.form.displayed_fields = [...this.feature_type.displayed_fields];
      }
    },

    setDefaultStyle(e) {
      const { color, icon, opacity } = e.value;
      this.form.color = color.value;
      this.form.icon = icon;
      this.form.opacity = opacity;
    },

    setColorsStyle(e) {
      const { name, value } = e;
      const { color, icon, opacity } = value;
      this.form.colors_style.colors[name] = color;
      this.form.colors_style.icons[name] = icon;
      this.form.colors_style.opacities[name] = opacity;
      this.form.colors_style.value.colors[name] = color;
      this.form.colors_style.value.icons[name] = icon;
      this.form.colors_style.value.opacities[name] = opacity; //? why do we need to duplicate values ? for MVT ?
    },

    toggleDisplay(evt, name) {
      if (evt.target.checked) {
        this.form.displayed_fields.push(name);
      } else {
        this.form.displayed_fields = this.form.displayed_fields.filter(el => el !== name);
      } 
    },

    sendDisplayConfig() {
      this.loading = true;
      this.SEND_FEATURE_DISPLAY_CONFIG(this.form)
        .then(() => {
          this.loading = false;
          this.$router.push({
            name: 'project_detail',
            params: {
              slug: this.$route.params.slug,
              message: { comment: `La modification de l'affichage du type de signalement "${this.feature_type.title}" a été prise en compte.`, level: 'positive' }
            },
          });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('DISPLAY_MESSAGE', {
            comment: `Une erreur est survenue pendant l'envoi des modifications de l'affichage du type de signalement "${this.feature_type.title}"`,
            level: 'negative'
          });
          this.loading = false;
        });
    },

    getOpacity(feature_type, optionName) {
      if (feature_type.colors_style.value && feature_type.colors_style.value.opacities) {
        return feature_type.colors_style.value.opacities[optionName];
      }
      return null;
    },

    getCustomFieldType(fieldType) {
      return customFieldTypeChoices.find(el => el.value === fieldType).name;
    }
  }
};
</script>

<style lang="less" scoped>
#displayCustomisation {
  h1 {
    margin-top: 1em;
  }
  form {
    text-align: left;
    margin-left: 1em;
    #customfield-select-label {
      font-weight: 600;
      font-size: 1.1em;
    }
    #custom_types-dropdown {
      margin: 1em;
      && > .dropdown {
        width: 50%;
      }
    } 
  }
}


#symbology, #popupDisplay {
  padding: 1.5em 0;
  // shrink toggle background width and height
  .ui.toggle.checkbox .box::after, .ui.toggle.checkbox label::after {
    height: 15px;
    width: 15px;
  }
  .ui.toggle.checkbox .box, .ui.toggle.checkbox label {
    padding-left: 2.5rem;
  }
  // reduce toggle button width and height
  .ui.toggle.checkbox .box::before, .ui.toggle.checkbox label::before {
    height: 15px;
    width: 35px;
  }
  // adjust toggled button placement
  .ui.toggle.checkbox input:checked ~ .box::after, .ui.toggle.checkbox input:checked ~ label::after {
    left: 20px;
  }
  .ui.toggle.checkbox .box, .ui.toggle.checkbox label, .ui.toggle.checkbox {
    min-height: 15px;
  }
  table {
    border-collapse: collapse;
  }
  tr.first_customfield td {
    border-top-width: 4px !important;
  }
}

</style>
